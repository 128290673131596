import { Link } from "react-router"
import { Button } from "~/components/ui/button"
import { Card } from "~/components/ui/card"

export default function NotFound() {
  return (
    <Card className="flex flex-col items-center h-screen justify-center">
      <h1 className="text-4xl font-bold mb-4">Not found</h1>
      <p className="text-lg mb-8">
        We couldn't find the page you're looking for.
      </p>
      <Button asChild>
        <Link to="/">Return home</Link>
      </Button>
    </Card>
  )
}
